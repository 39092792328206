import { showErrorSnackbar } from "@doopage/react-ui-kit";
import { t } from "@lingui/macro";
import {
    getCallAccount,
    getCurrentSubscription,
    getSellerProfile,
} from "api/api.js";
import axios from "axios";
import { ENV, IS_SERVER_LOCAL } from "config/Env";
import { CallServiceHelper } from "helper/call";
import _ from "lodash";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { isMyInbox } from "../components/DoopageChat/Message/helpersMessage";
import LocalStorage from "../config/LocalStorage";
import DV from "../variables/DV";
import {
    ASIGN_SEGMENT,
    isFilterChannel,
    mapInboxState,
    mapMessageState,
    mapMessageType,
    MIN_WINDOW_WIDTH,
    WIDTH_LIST_INBOX,
    WIDTH_PRESETS,
} from "../variables/staticValue";
import { MAP_MESSAGE_TYPE_DETAIL } from "../variables/staticValueMessage";

const pjson = require("../../package.json");

export const handleApi = async (func, obj) => {
    return func(obj)
        .then((data) => {
            return { success: true, data: data };
        })
        .catch((errorMsg) => {
            return { success: false, error: errorMsg };
        });
};

export const handlePromise = async (pro) => {
    return pro
        .then((data) => {
            return { success: true, data: data };
        })
        .catch((err) => {
            return { success: false, error: err?.message || err };
        });
};

export const toVND = (value) => {
    if (typeof value === "string") value = parseInt(value);
    if (value)
        return value.toLocaleString("it-IT", {
            style: "currency",
            currency: "VND",
        });
    return value;
};

export const getFormData = (data) => {
    if (!data) return null;
    let formData = new FormData();

    Object.keys(data).forEach((key) => {
        if (data[key]) formData.append(key, data[key]);
    });

    return formData;
};

export const validateDomain = (domain) => {
    var re = new RegExp(
        /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
    );
    return domain.match(re);
};

export const validateEmail = (email) => {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
};

export const validatePhone = (phone) => {
    console.log("decoratedText", phone);
    let re = /^(0|\+84|84)+(9|3|7|8|5)([0-9]{8})\b$/;
    return re.test(phone);
};

export const validateHTTP = (url) => {
    let pattern = /^((http|https|ftp):\/\/)/;
    if (url && pattern.test(url.toString())) {
        return true;
    }
    return false;
};

export const validateSIPUriIsPhone = (string) => {
    let pattern = /^\+?[0-9]+$/gm;
    if (string && pattern.test(string)) {
        return true;
    }
    return false;
};

export const getObjectFromParams = (params) => {
    try {
        if (params && params.length > 1)
            return JSON.parse(
                '{"' +
                    decodeURI(
                        params
                            .substr(1)
                            .replace(/&/g, '","')
                            .replace(/=/g, '":"')
                    ) +
                    '"}'
            );
    } catch (e) {
        console.warn("can not parse query params", params);
    }

    return {};
};

export const getParamsFromObject = (obj) => {
    let str = [];
    for (let key in obj)
        if (obj[key] !== null && obj[key] !== undefined)
            str.push(
                encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
            );
    if (!str.length) return "";
    return "?" + str.join("&");
};

export const convertHttpToHttps = (url) => {
    if (IS_SERVER_LOCAL) return url;
    if (url.includes("http") && !url.includes("https")) {
        url = url.replace("http", "https");
    }
    return url;
};

export const addAmountOfProduct = (a) => {
    if (a == null) return [];
    let myArray = [];
    a.forEach((product) => {
        myArray.push({
            id: product.id,
            name: product.name,
            price: product.price,
            unit: product.unit,
            image: product.image,
            description: product.description,
            note: product.note,
            amount: 0,
        });
    });

    return myArray;
};

export const changeAlias = (alias, keepSpecialChar = false) => {
    let str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    if (!keepSpecialChar) {
        str = str.replace(
            /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
            " "
        );
    }

    str = str.replace(/ + /g, " ");
    str = str.trim();
    // str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return str;
};

export const isMatchKeyword = (fullSt, keyword) => {
    return changeAlias(fullSt).includes(changeAlias(keyword));
};

export const uniqueArray = (array) => {
    if (array.length < 2) return array;
    for (let i = 0; i < array.length - 1; i++) {
        for (let j = i + 1; j < array.length; j++) {
            if (array[i].id === array[j].id) {
                array.splice(j, 1);
            }
        }
    }
    return array;
};

export const getColorSegment = (name) => {
    return ASIGN_SEGMENT[name] || { color: "gray" };
};

export const validateQTY = (qty) => {
    return parseInt(qty > 0);
};

export const equal = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
};

export const getHeightModal = (height = 0) => {
    return window.innerHeight * 0.9 - 57 - 40 - 24 - height;
};

export const getDate = (date, format = "DD/MM/YYYY") => {
    return date ? moment(date).locale("vi").format(format) : "N/A";
};

export const checkText = (text, replaceText = "Zombie bie", env = "dev") => {
    if (env !== ENV) return text || "";
    if (text && changeAlias(text).toLowerCase().includes("ba dien"))
        return replaceText;
    if (text && changeAlias(text).toLowerCase().includes("son"))
        return replaceText;
    return text;
};

export const toCurrency = (value, currency) => {
    currency = currency || "VND";

    if (!value) return "0";
    try {
        if (value !== 0)
            return Number(value).toLocaleString("it-IT", {
                style: "currency",
                currency,
            });
    } catch (e) {
        return value;
    }

    return value;
};

export const checkPartnerSite = async (partner) => {
    if (
        !localStorage.getItem("doopage_fake_owner_id") &&
        !localStorage.getItem("doopage_fake_owner_token") &&
        DV.staffDeviceId
    ) {
        if (partner && partner.center_domain) {
            if (!window.location.href.startsWith(partner.center_domain)) {
                window.location.href =
                    partner.center_domain + "?device_id=" + DV.staffDeviceId;
            }
        }
    }
};

export const setupCallService = async () => {
    if (DV.callService) {
        await DV.callService.disconnect();
    }

    const { data, error } = await handleApi(getCallAccount());
    if (error) return { error };

    DV.callAccount = data;
    DV.callService = new CallServiceHelper(data);
    await DV.callService.connect();
    await DV.callService.recover();
    return DV.callService;
};

export const getSellerInfo = async () => {
    let deviceId =
        DV.params.device_id || (await LocalStorage.getStaffDeviceId());
    if (DV.params.device_id) LocalStorage.setStaffDeviceId(DV.params.device_id);
    const isLocalHost = window.location.origin.toString().includes("localhost");

    DV.staffDeviceId = deviceId;
    let params = "";
    if (deviceId) params = params + "?device_id=" + deviceId;
    let { data, error } = await handleApi(getSellerProfile, params);
    if (error) return { error };
    if (data?.last_staff?.company && !isLocalHost) {
        await checkPartnerSite(data.last_staff.company.partner);
    }
    DV.user = data;
    LocalStorage.setUser(data);
    if (data?.last_staff?.is_support_call) {
        setTimeout(setupCallService);
    }
    return { data };
};

export const getCurrentSubs = async () => {
    let { data, error } = await handleApi(getCurrentSubscription());
    if (error) {
        showErrorSnackbar(error || t`Có lỗi xảy ra, vui lòng thử lại`);
        return false;
    }
    DV.currentSubscription = data;
    return true;
};

export const isFilterOnlyMessage = (filterInfo) => {
    return filterInfo?.message_type === "only_message";
};

export const isFilterOnlyComment = (filterInfo) => {
    return filterInfo?.message_type === "only_comment";
};

export const isFilterBlockedCustomer = (filterInfo) => {
    return filterInfo?.inbox_state?.includes("blocked") ?? false;
};

export const truncateText = (text, maxLength = 10) => {
    let truncated;
    if (text.length > maxLength) {
        truncated = text.substr(0, maxLength) + "...";
    }
    return truncated;
};

export const getLinkProductShopee = (shopId, productId) => {
    return `https://shopee.vn/product/${shopId}/${productId}`;
};

export const getBuildNumber = () => {
    const version = pjson.version || "";
    const temp = version.split("+");
    return temp[temp.length - 1];
};

export const getPanelChatContainerWidth = () => {
    return (
        Math.max(window.innerWidth, MIN_WINDOW_WIDTH) -
        WIDTH_LIST_INBOX -
        WIDTH_PRESETS
    );
};

export const shouldRenderInbox = (inbox) => {
    //Filter channel
    if (DV.filterInfo?.channels?.length > 0) {
        if (!isFilterChannel(inbox?.channel, DV.filterInfo?.channels))
            return false;
    }

    //Filter message state
    if (DV.filterInfo?.message_state?.includes("not_read")) {
        if (inbox?.is_read) return false;
    }

    if (DV.filterInfo?.message_state?.includes("not_reply")) {
        if (inbox?.is_reply) return false;
    }

    if (isFilterBlockedCustomer(DV.filterInfo)) {
        if (!inbox.is_block) return false;
    } else {
        if (inbox.is_block) return false;
    }

    //Filter Message type
    const { customer_comment, reply_comment, customer_message } =
        MAP_MESSAGE_TYPE_DETAIL;
    const lastMessageType = inbox?.last_message?.message_type;

    if (isFilterOnlyComment(DV.filterInfo)) {
        if (lastMessageType === customer_message) return false;
    }

    if (isFilterOnlyMessage(DV.filterInfo)) {
        if (
            lastMessageType === customer_comment ||
            lastMessageType === reply_comment
        )
            return false;
    }

    //Filer by inbox state
    const inboxState = DV.filterInfo?.inbox_state ?? [];
    if (inboxState.includes("not_taked") && inboxState.includes("mine")) {
        if (!!inbox?.seller && !isMyInbox(inbox?.seller)) return false;
    } else if (inboxState.includes("not_taked") && !!inbox?.seller) {
        return false;
    } else if (inboxState.includes("mine") && !isMyInbox(inbox?.seller)) {
        return false;
    }

    if (DV.filterInfo?.tags?.length > 0) {
        const filterTags = DV.filterInfo?.tags;
        return inbox?.tags?.some((item) => filterTags.includes(item?.id));
    }

    if (DV.filterInfo?.segments?.length > 0) {
        const filterSegments = DV.filterInfo?.segments;
        return filterSegments.includes(inbox?.segment?.id);
    }

    if (DV.filterInfo?.sellers?.length > 0) {
        const sellers = DV.filterInfo?.sellers;
        return sellers.includes(inbox?.seller?.id);
    }

    return true;
};

export const openWindowCenter = (url, target, width, height) => {
    let left = window.screen.width / 2 - width / 2;
    let top = window.screen.height / 2 - height / 2;
    return window.open(
        url,
        target,
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
            width +
            ", height=" +
            height +
            ", top=" +
            top +
            ", left=" +
            left
    );
};

export const convertFilterDataForBackend = (state) => {
    let {
        checkedChannels,
        checkedSegments,
        checkedSellers,
        checkedTags,
        inboxState,
        messageState,
        messageType,
    } = state;
    let dataFilter = {};
    if (checkedSegments)
        dataFilter.segments =
            checkedSegments?.map((item) => item?.id ?? item) ?? [];
    if (inboxState) dataFilter.inbox_state = inboxState;
    if (messageState) dataFilter.message_state = messageState;
    if (messageType) dataFilter.message_type = messageType;
    if (checkedTags)
        dataFilter.tags = checkedTags?.map((item) => item?.id ?? item) ?? [];
    if (checkedChannels) dataFilter.channels = checkedChannels;
    if (checkedSellers) dataFilter.sellers = checkedSellers;

    DV.filterParams = dataFilter;
    return dataFilter;
};

export const getParamsForInboxList = (keyword, getMore, beforeTime) => {
    let params = `?`;
    if (getMore && beforeTime > 0) params = params + "&before=" + beforeTime;
    if (!!keyword) params = params + "&q=" + changeAlias(keyword, true);

    const { showInboxSource, ...filterData } = LocalStorage.getFilter();
    let filterDataForBackend = convertFilterDataForBackend(filterData);

    if (isEmpty(filterDataForBackend)) return params;

    const {
        segments,
        tags,
        channels,
        message_state,
        message_type,
        inbox_state,
        sellers,
    } = filterDataForBackend;
    if (_.size(segments) > 0) {
        segments.forEach((item) => (params = params + "&segments=" + item));
    }

    if (_.size(tags) > 0) {
        tags.forEach((item) => (params = params + "&tags=" + item));
    }

    if (_.size(channels) > 0) {
        channels.forEach(
            (item) =>
                (params =
                    params +
                    `&platform=${item?.platform}&platform_id=${item?.id}`)
        );
    }

    if (_.size(message_state) > 0) {
        message_state.forEach((i) => {
            params += mapMessageState[i];
        });
    }

    if (_.size(inbox_state) > 0) {
        inbox_state.forEach((i) => {
            params += mapInboxState(DV.user?.seller?.id)[i];
        });
    }

    if (_.size(sellers) > 0) {
        sellers.forEach((i) => {
            params += "&seller_id=" + i;
        });
    }

    params += mapMessageType[message_type]?.queryStr;
    DV.filterMessage = mapMessageType[message_type]?.filterMessage;

    return params;
};

export const getPermanentIP = async () => {
    const ip = LocalStorage.getPermanentIP();
    if (!isEmpty(ip)) return ip;

    try {
        const result = await axios.get("https://ipinfo.io/json");
        LocalStorage.setPermanentIP(result.data);

        return result.data;
    } catch (e) {
        console.log(e);
    }
    return {};
};

export const dateStringToTimeStamp = (str) => {
    try {
        return new Date(str).getTime();
    } catch (e) {
        return 0;
    }
};

export const mergeInbox = (inbox1, inbox2) => {
    let localInbox;
    let apiInbox;
    if (inbox1.isLocal) {
        localInbox = inbox1;
        apiInbox = inbox2;
    } else {
        localInbox = inbox2;
        apiInbox = inbox1;
    }
    if (
        dateStringToTimeStamp(localInbox?.last_message?.created_time) >
        dateStringToTimeStamp(apiInbox?.last_message?.created_time)
    ) {
        return {
            ...localInbox,
            ...apiInbox,
            last_message: localInbox.last_message,
            last_customer_message_timestamp:
                localInbox.last_customer_message_timestamp,
            last_customer_message_time: localInbox.last_customer_message_time,
            customer_read_message_time: localInbox.customer_read_message_time,
            out_of_7d: localInbox.out_of_7d,
            out_of_24h: localInbox.out_of_24h,
            un_replied_count: localInbox.un_replied_count,
        };
    }
    return { ...localInbox, ...apiInbox };
};

export const hasCallCenterPackage = () => {
    const { currentSubscription = {} } = DV;
    let { coupon_subscription, main_subscription } = currentSubscription;
    const subs = main_subscription || coupon_subscription || {};
    if (subs) {
        return (
            subs.call_agent_number > 0 &&
            subs.call_agent_number === subs.staff_number
        );
    }
    return false;
};

export const searchInArray = (arr, keyword, fields) => {
    return arr.filter((i) => {
        for (const f of fields) {
            if (changeAlias(i[f]).includes(changeAlias(keyword))) return true;
        }
        return false;
    });
};

export const getBrowserName = () => {
    const { detect } = require("detect-browser");
    const browser = detect();
    return browser.name;
};

export const isIphoneOrIpad = () => {
    return /(iPhone|iPad)/.test(navigator.userAgent);
};

export function getVersionWithoutBuildNumber(version) {
    const [versionWithoutBuild] = version.split("+");
    return versionWithoutBuild;
}

export function getVersionPath() {
    const pathname = window.location.pathname;
    const segments = pathname
        .split("/")
        .filter((segment) => segment.length > 0);
    return segments.length > 0 ? `/${segments[0]}` : "";
}
