import moment from "moment";
import shallowequal from "shallowequal";
import DV from "variables/DV";
import { MAP_MESSAGE_TYPE_DETAIL } from "variables/staticValueMessage";
import { LIST_DOMAIN } from "variables/staticValue";
import isEmpty from "lodash/isEmpty";
import { isSellerMessage } from "@doopage-client-js/common";

export const getPositionY = (ub, ua, db, da, pb, pa, tb, ta) => {
    if ((!ub && !ua) || (!db && !da) || (!pb && !pa) || (!tb && !ta))
        return "top-bottom";
    if ((!ub || !db || !pb || !tb) && (!ua || !da || !pa || !ta))
        return "top-bottom";
    if (!ub || !db || !pb || !tb) return "top";
    if (!ua || !da || !pa || !ta) return "bottom";
    return "middle";
};

export const getPosition = ({ message_type }) => {
    if (message_type === MAP_MESSAGE_TYPE_DETAIL.customer_comment)
        return "left";
    if (message_type === MAP_MESSAGE_TYPE_DETAIL.customer_message)
        return "left";
    return "right";
};

export const isMyInbox = (seller) => {
    return seller && seller?.id === DV.user?.seller?.id;
};

export const isMyMessage = (message) => {
    return isSellerMessage(message) && message?.sender?.id === DV.user?.seller?.id;
};

export const showAvatar = (currentMessage) => {
    const { message_type } = currentMessage;
    if (message_type >= 80) return true;

    if (
        [
            MAP_MESSAGE_TYPE_DETAIL.customer_comment,
            MAP_MESSAGE_TYPE_DETAIL.customer_message,
        ].includes(message_type)
    )
        return true;
    return !isMyMessage(currentMessage);
};

export const getMessageAvatar = (currentMessage) => {
    const { message_type, sender } = currentMessage;
    if (
        message_type === MAP_MESSAGE_TYPE_DETAIL.doopage_bot ||
        message_type >= 90
    )
        return { avatar: LIST_DOMAIN[DV.partner].logo };
    if (!isEmpty(sender)) return { avatar: sender?.avatar, name: sender?.name };
    return {};
};

export function isSameDay(currentMessage = {}, diffMessage = {}) {
    if (!diffMessage || !diffMessage.created_time) {
        return false;
    }

    const currentCreatedAt = moment(currentMessage.created_time);
    const diffCreatedAt = moment(diffMessage.created_time);

    if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
        return false;
    }

    return currentCreatedAt.isSame(diffCreatedAt, "day");
}

export function isSameUser(currentMessage = {}, diffMessage = {}) {
    if (!diffMessage || !diffMessage.sender || !currentMessage.sender) {
        return false;
    }
    return (
        (currentMessage.seller &&
            currentMessage.seller.id === diffMessage.seller?.id) ||
        (currentMessage.customer &&
            currentMessage.customer.id === diffMessage.customer?.id)
    );
}

export const isSamePage = (currentMessage = {}, diffMessage = {}) => {
    if (!currentMessage.page && diffMessage && !diffMessage.page) return true;
    if (!currentMessage.page || !diffMessage || !diffMessage.page) return false;
    return shallowequal(currentMessage.page.id, diffMessage.page.id);
};

export const isSameType = (currentMessage = {}, diffMessage = {}) => {
    if (
        !currentMessage.message_type &&
        diffMessage &&
        !diffMessage.message_type
    )
        return true;
    if (
        !currentMessage.message_type ||
        !diffMessage ||
        !diffMessage.message_type
    )
        return false;
    return shallowequal(currentMessage.message_type, diffMessage.message_type);
};
