import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import Network from "addOnComponent/Network";
import { GA_ID } from "config/Env";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "router/components/PrivateRoute";
import { appRoutes } from "router/routes";
import Analytics from "react-router-ga";
import DV, { getLogo } from "variables/DV";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../MUI_Kit/assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "../../MUI_Kit/assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import buttonStyle from "../../MUI_Kit/assets/jss/material-dashboard-pro-react/components/buttonStyle";
import customCheckboxRadioSwitch from "../../MUI_Kit/assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import tooltipsStyle from "../../MUI_Kit/assets/jss/material-dashboard-pro-react/components/tooltipsStyle";
import customSelectStyle from "../../MUI_Kit/assets/jss/material-dashboard-pro-react/customSelectStyle";
import { useConstructor } from "../../hooks/useContructor";
import LocalStorage from "../../config/LocalStorage";
import { loadLanguage } from "../../config/LinguiConfig";
import { LIST_DOMAIN } from "../../variables/staticValue";
import { getObjectFromParams } from "../../helper/helper";
import { CHAT_MODE } from "../../variables/staticValueMessage";
import Snackbar from "MUI_Kit/components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";
import {
    useAuth,
    useMessengerClientContext,
} from "@doopage-client-js/messenger-react";
import LoadingComponent from "addOnComponent/LoadingComponent";
import ErrorComponent from "addOnComponent/ErrorComponent";
import { valtio } from "@doopage-client-js/common";
import { appState, setAppState } from "state/app";

const styles = (theme) => ({
    ...dashboardStyle,
    ...loginPageStyle,
    ...buttonStyle,
    ...customCheckboxRadioSwitch,
    ...tooltipsStyle,
    ...customSelectStyle,
    paper: {
        borderRadius: 20,
    },
});
const switchRoutes = (
    <Switch>
        {appRoutes.map((route, key) => {
            if (route.private)
                return (
                    <PrivateRoute
                        path={route.path}
                        component={route.component}
                        key={key}
                    />
                );

            return <Route exact key={key} {...route} />;
        })}
    </Switch>
);

const App2 = (props) => {
    const title = "DooPage";
    const [notify, setNotify] = useState({
        message: "",
        color: "",
    });
    const auth = useAuth();
    const isLoadingAuth = true;
    const staff = undefined;
    // const { isLoading: isLoadingAuth, staff } = useAuthState();

    useLayoutEffect(() => {
        const lastVersion = localStorage.getItem("lastVersion");
        if (window.location.pathname === "" || window.location.pathname === "/" && lastVersion) {
            window.location.href= window.location.origin+"/"+lastVersion;
        }
    }, []);

    useEffect(() => {
        if (!auth || isLoadingAuth) return;
        const tokenData = DV.params.doo_token_data;
        if (!tokenData) return;
        const [access, refresh, socket] = tokenData.split("|");
        if (access) {
            auth.tokenAuth(access, refresh, socket).catch((e) => {
                console.warn("auth by token fail", e);
            });
        }
    }, [auth, isLoadingAuth]);

    const init = useCallback(() => {
        const language = LocalStorage.getLang();

        loadLanguage(language);

        let domain = window.location.href.toString();
        DV.partner = Object.keys(LIST_DOMAIN).find((key) =>
            domain.includes(key)
        );
        DV.classes = props.classes;
        DV.params = getObjectFromParams(window.location.search);
        DV.url = window.location.pathname;
        if (DV.params?.viewMode === "single") {
            DV.chatMode = CHAT_MODE.single;
        } else if (DV.params?.viewMode === "multi") {
            DV.chatMode = CHAT_MODE.multi;
        }
    }, [props.classes]);

    useConstructor(() => {
        init();
    });

    return (
        <div
            style={{ height: "100vh", overflowY: "hidden" }}
            key={staff?.company?.id || 0}
        >
            <Helmet>
                <title>{title}</title>
                <link rel="shortcut icon" href={getLogo().indexLogo} />
            </Helmet>
            <Network polling={false} />
            <Snackbar
                place="tr"
                icon={AddAlert}
                color={notify.color}
                message={notify.message || ""}
                open={!!notify.message}
                closeNotification={() => {
                    setNotify((prev) => {
                        return { ...prev, message: "" };
                    });
                }}
                close
            />
            <BrowserRouter>
                <Analytics id={GA_ID}>{switchRoutes}</Analytics>
            </BrowserRouter>
        </div>
    );
};

const AppWrapper = (props) => {
    const { client, error, isLoading } = useMessengerClientContext();
    // console.log("AppWrapper", { client, error, isLoading });

    useEffect(() => {
        // Load app state from KVStorage
        if (isLoading || !client || !!error) return;
        (async () => {
            const aState = await client.kvStorage.getItem("CDooAS");
            if (aState) {
                setAppState(aState);
            }
        })();
        return valtio.subscribe(appState, () => {
            client.getKVStorage().setItem("CDooAS", appState);
        });
    }, [client, isLoading, error]);

    // console.log("AppWrapper", client, error);
    if (error) {
        return <ErrorComponent error={error} />;
    }
    if (!client || isLoading) {
        return <LoadingComponent />;
    }

    return (
        <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
            <App2 {...props} />
        </I18nProvider>
    );
};

export default withStyles(styles)(AppWrapper);
